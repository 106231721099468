import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ImagesService } from '../../shared';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  private roadmapUrl = `${environment.roadmapUrl}`;
  images;

  constructor(
    private router: Router,
    private imageService: ImagesService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.images = this.imageService.imgSrc;
    return this.images;
  }

  onAddNewUser(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['users/new']);
  }
  odAddNewDevice(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['devices/new']);
  }
  onAddNewBrand(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['brands/new']);
  }
  onAddNewModel(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['models/new']);
  }
  onAddWarranty(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['warranties/new']);
  }
  onAddNewVariants(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['variants/new']);
  }
  onAddStyle(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['style-configurator/form/new']);
  }
  navigateToRoadmap() {
    let loggedUser = localStorage.getItem('current_user') as any;
    window.open(`${this.roadmapUrl}?user=${loggedUser}&redirected=admin`);
  }
}
